import React from 'react'
import TcgList from './tcgList'

function index(){

    return(
        <div>
            <h1>TCG Tracker</h1>
            <TcgList />
        </div>
    );

}

export default index;