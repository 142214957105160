import React, {useEffect, useState} from 'react';

function PasswordInput(props){
console.log(props)
    // return (
    //     <div>
    //         <input 
    //             type={confirmNewPasswordType}
    //             name="confirm-new-password"
    //             value={confirmNewPassword}
    //             onChange={e => handleChange(e)}    
    //         />
    //         <input 
    //             type="checkbox"
    //             onClick={(e) => handleClick(e)}
    //         /> Show Password
    //     </div>
    // )
}

export default PasswordInput