import React from 'react'

function Loading(){

    return(
        <div className="loader">
        </div>
    )

}

export default Loading